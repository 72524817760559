import Flickity from "react-flickity-component"
import Image from "../../components/image/image"
import Layout from "../../components/layout"
import PrevNext from "../../components/prev-next/prev-next"
import ProjectFacts from "../../components/project-facts/project-facts"
import React from "react"
import Section from "../../components/section/section"
import Seo from "../../components/seo"
import Stage from "../../components/stage/stage"
import gfeFlowImg from "../../images/projects/deputy-compliance/gfe-flow.png"
import gfeFlowImg2 from "../../images/projects/deputy-compliance/gfe-flow-2.png"
import howMightWeImg from "../../images/projects/deputy-compliance/how-might-we.png"
import introImg from "../../images/projects/deputy-compliance/intro.png"
import lightbulbImg from "../../images/projects/deputy-compliance/lightbulb.svg"
import peopleImg from "../../images/projects/deputy-compliance/people.svg"
import requirementsImg from "../../images/projects/deputy-compliance/requirements.png"
import researchImg from "../../images/projects/deputy-compliance/research.jpg"
import uiImg1 from "../../images/projects/deputy-compliance/ui-1.png"
import uiImg2 from "../../images/projects/deputy-compliance/ui-2.png"
import uiImg3 from "../../images/projects/deputy-compliance/ui-3.png"
import uiImg4 from "../../images/projects/deputy-compliance/ui-4.png"
import uiImg5 from "../../images/projects/deputy-compliance/ui-5.png"
import uiImg6 from "../../images/projects/deputy-compliance/ui-6.png"
import uiImg7 from "../../images/projects/deputy-compliance/ui-7.png"

const DeputyCompliance = () => (
  <Layout>
    <Seo title="New York Fairworkweek" />

    <Section>
      <h1>New York Fairworkweek</h1>
      <p>
        New York Fairworkweek legislation has been introduced to protect
        part-time workers in retail and foodservice industries. The law requires
        business owners to share employee schedules in advance and compensate
        their employees if schedules change without proper notice.
      </p>

      <ProjectFacts
        className="mb-4 border-top"
        name="Deputy"
        type="UX/UI Design"
        year="2020"
      />

      <Image img={introImg} />

      <div className="row">
        <div className="col-12 col-md-6 border-right pr-md-5">
          <h2 className="mb-4">Challenge</h2>

          <p>
            The current company system did not provide substantial visibility
            and full assistance to customers in the USA with Fairworkweek.
          </p>
          <p>
            Customers are required to be informed and guided about what’s going
            on in the system through appropriate recommendations within a
            reasonable time and place.
          </p>
        </div>
        <div className="col-12 col-md-6 pl-md-5">
          <h2 className="mb-4">My role</h2>

          <p>
            As the design lead in the Compliance team, I was responsible for the
            following:
          </p>

          <ul>
            <li>
              Research and understand current customer’s understanding and
              experience on Fairworkweek in New York city
            </li>
            <li>
              Learn about customer expectations and requirements on NY
              Fairworkweek
            </li>
            <li>Present research findings</li>
            <li>
              Work closely with PM, Sales and Legal teams on defining a roadmap,
              its phases and jobs-to-be-done
            </li>
            <li>User experience and interface design </li>
          </ul>
        </div>
      </div>
    </Section>

    <Section heading="Outcome" background={true}>
      <ul>
        <li>Clear visibility and storage of Good Faith Estimate</li>
        <li>
          Automated workflows to issue and re-issue of Good Faith Estimate
        </li>
        <li> Ongoing recommendations with schedule updates </li>
        <li> Bulk employee scheduling </li>
        <li> Easy access to records</li>
      </ul>
    </Section>

    <Section heading="What did the process look like?">
      <div className="d-flex justify-content-between flex-column flex-sm-row mb-5">
        <Stage stage="Discovery">
          <ul className="text-left">
            <li>Stakeholder discussions</li>
            <li>Competitor analysis</li>
            <li>User interviews</li>
            <li>Internal interviews</li>
          </ul>
        </Stage>
        <Stage stage="Analysis">
          <ul className="text-left">
            <li>Experience map</li>
            <li>User flows</li>
            <li>Feedback synthesis</li>
          </ul>
        </Stage>
        <Stage stage="report">
          <ul className="text-left">
            <li> Key findings</li>
            <li>Recommendations</li>
            <li>Play back</li>
            <li>Next steps</li>
          </ul>
        </Stage>
        <Stage stage="Design">
          <ul className="text-left">
            <li>Wireframes</li>
            <li>Stakeholder feedback</li>
            <li>User Interface</li>
            <li>User feedback</li>
          </ul>
        </Stage>
        <Stage stage="Validate">
          <ul className="text-left">
            <li>Internal feedback</li>
            <li>QA</li>
            <li>Cusromer feedback</li>
          </ul>
        </Stage>
      </div>

      <h3>Discovery</h3>
      <p>
        Our first step in understanding the landscape was to review the current
        system state and learn about the USA Fireworkweek legal requirements to
        identify the main gaps.
      </p>
      <p>Main Fairworkweek laws business requirements:</p>
      <ul className="mb-5">
        <li>
          Provide employee schedules in advance and take employee’s scheduling
          requests into consideration
        </li>
        <li>
          Provide all employees with Good Faith Estimate of the hours they can
          expect to work
        </li>
        <li>
          Pay employees a higher rate if their work schedules are changed or
          their hours are reduced
        </li>
        <li>Ensure easy access to employee schedules</li>
        <li>
          Scheduling employee shifts in accordance with employee ‘rest periods’
        </li>
      </ul>
      <Image
        img={requirementsImg}
        caption="7 rules of NY Fairworkweek for quick service restaurants (QSR)"
      />
    </Section>

    <Section>
      <h3 className="mb-4">Research</h3>

      <table className="table table-bordered mb-5">
        <thead>
          <tr>
            <th scope="col">
              <div className="d-flex align-items-center">
                <h4 className="mt-2 mr-3">Who did we talk to?</h4>
                <img src={peopleImg} alt="Who did we talk to?"/>
              </div>
            </th>
            <th scope="col">
              <div className="d-flex align-items-center">
                <h4 className="mt-2 mr-3">What did we want to learn?</h4>
                <img src={lightbulbImg} alt="What did we want to learn?"/>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <h5>Internal</h5>

              <ul>
                <li>Sales</li>
                <li>Legal</li>
                <li>Engineers</li>
              </ul>
            </td>
            <td>
              <p>
                General understanding of customer’s expectations and requests:
              </p>
              <ul>
                <li>
                  How often do customers express interest in Deputy being FWW
                  compliant?
                </li>
                <li>What are the main requirements/requests from them?</li>
                <li>What are the customer expectations from Deputy?</li>
                <li>Where does Deputy sit compare to its US competitors?</li>
                <li>
                  What are the biggest US competitors that assist customers to
                  be 100% FWW compliant?
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <h5>External</h5>
              <p>
                New and existing customers based in the USA who are impacted by
                NY Fairworkweek:
              </p>
              <ul>
                <li>
                  QSR (quick service restaurants) employers with 30 or more
                  locations
                </li>
                <li>
                  Emerging retail employers with 250 - 750 employees engaged
                  primarily in the sale of consumer goods.
                </li>
              </ul>
            </td>
            <td>
              <p>
                General understanding of customer’s current experience,
                knowledge and pain points on the following:
              </p>
              <ul>
                <li>NY FWW background </li>
                <li>Schedules in advance </li>
                <li>Electronic copies </li>
                <li>10-hour window rule</li>
                <li> Good Faith Estimate </li>
                <li>Additional pay condition </li>
                <li>Access to records(Audit)</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>

      <Image img={researchImg} caption="Feedback analysis" />

      <h3>Competitor analysis</h3>
      <p>
        From internal interviews with our Sales team based in the USA I
        identified a few direct and indirect competitors and did analysis of
        their products to understand how they position themselves and what they
        do well on the Compliance Fairworkweek side.
      </p>
    </Section>

    <Section background={true}>
      <h3>Key Research findings</h3>
      <p>There were a number of repeated patterns:</p>
      <ol>
        <li>
          New and existing customers require guidance on how to follow FWW rules
          and stay compliant
        </li>
        <li>
          Automated workflows required to reduce manual entry, increase accuracy
          and save time
        </li>
        <li>
          When users make schedule related changes, they expect updates and
          recommendations to be aware of the impact
        </li>
        <li> Easy access and visibility of records to be audit-ready</li>
      </ol>
    </Section>

    <Section>
      <h3>Planning</h3>
      <p className="mb-5">
        Since the Good Faith Estimate is the first and main component of the
        Fairworkweek it bacame our first priority on the roadmap. I mapped out
        step by step flows for both businesses which require to onboard their
        new and existing employees with Good Faith Estimate (GFE) and later,
        issue an updated GFE if it’s changed. The flows helped me and the team
        to understand the bigger picture and break down work into phases.
      </p>

      <Image
        img={gfeFlowImg}
        caption="Issue of Goof Faith Estimate for new hires flow"
      />
      <Image
        img={gfeFlowImg2}
        caption="Issue and re-issues of Goof Faith Estimate flow"
      />
    </Section>

    <Section>
      <h3>How Might We</h3>
      <p className="mb-5">
        How Might We format was a perfect design thinking exercise to help us
        turn our customer problems into opportunities.
      </p>

      <Image img={howMightWeImg} />
    </Section>

    <Section heading="User Interface" background={true}>
      <p>
        Based on customer’s feedback and legal reviews, I designed the following
        Good Faith Estimate screens:
      </p>
      <ul className="mb-5">
        <li>Prompt for Onboarding Managers to create GFE for new hires</li>
        <li>New GFE and options to save or use later as templates</li>
        <li>Prompt to update employee’s GFE when it changed by 20%</li>
        <li>
          Easy access and GFE history overview for admin or audit purposes
        </li>
      </ul>

      <div className="bg-carousel">
        <Flickity
          className={"image-carousel"} // default ''
          elementType={"div"} // default 'div'
          options={{
            initialIndex: 0,
          }}
          disableImagesLoaded={false} // default false
          reloadOnUpdate // default false
          static // default false
        >
          <div className="image-cell">
            <img src={uiImg1} alt="Screen designs"/>
          </div>
          <div className="image-cell">
            <img src={uiImg2} alt="Screen designs"/>
          </div>
          <div className="image-cell">
            <img src={uiImg3} alt="Screen designs"/>
          </div>
          <div className="image-cell">
            <img src={uiImg4} alt="Screen designs"/>
          </div>
          <div className="image-cell">
            <img src={uiImg5} alt="Screen designs"/>
          </div>
          <div className="image-cell">
            <img src={uiImg6} alt="Screen designs"/>
          </div>
          <div className="image-cell">
            <img src={uiImg7} alt="Screen designs"/>
          </div>
        </Flickity>
      </div>
    </Section>

    <Section>
      <h3>Impact</h3>
      <ul>
        <li>1 step closer to enable better compliance experience</li>
        <li>
          Ongoing visibility of schedule updates (for both employer and
          employees)
        </li>
        <li>Reduced manual entry when scheduling employees (for business)</li>
        <li>Entry and storage of Good Faith Estimate</li>
        <li>Seamless auditability</li>
      </ul>
      <br />
      <br />
      <h3>Up next</h3>
      <p className="mb-5">
        This is a current project, so stay tuned for more Fairworkweek design
        solutions.
      </p>

      <PrevNext prev="/project/tournament-app" />
    </Section>
  </Layout>
)

export default DeputyCompliance
